import React from "react"
import { Box } from "@mui/material"
import IconHammerBr from "../../images/icon-hammer-br.svg"

const HammerBreak = () => {
  return (
    <Box my={`1rem`}>
      <Box component="img" src={IconHammerBr} display="block" m={`0 auto`} />
    </Box>
  )
}

export default HammerBreak
