import { red } from "@mui/material/colors"
import { createTheme } from "@mui/material/styles"

// A custom theme for this app
const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#2A1B36",
    },
    secondary: {
      main: "#70B2AF",
    },
    background: {
      default: "#2a1b36",
      paper: "#2a1b36",
    },
    text: {
      primary: "rgba(255,255,255)",
      secondary: "#C8B7D6",
      disabled: "rgba(255,255,255,0.38)",
      hint: "rgba(255,255,255,0.38)",
    },
    divider: "rgba(255,255,255,0.12)",
  },
  typography: {
    h1: {
      fontFamily: "Cinzel Decorative",
    },
    h2: {
      fontFamily: "Cinzel Decorative",
    },
    h3: {
      fontFamily: "Spectral",
    },
    h4: {
      fontFamily: "Spectral",
    },
    h5: {
      fontFamily: "Spectral",
    },
    h6: {
      fontFamily: "Spectral",
    },
    subtitle1: {
      fontFamily: "Spectral",
    },
    subtitle2: {
      fontFamily: "Spectral",
    },
    body1: {
      fontFamily: "Spectral",
    },
    body2: {
      fontFamily: "Spectral",
    },
    button: {
      fontFamily: "Spectral",
    },
    caption: {
      fontFamily: "Spectral",
    },
    overline: {
      fontFamily: "Spectral",
    },
  },
})

export default theme
