import React, { useState } from "react"
import { navigate } from "gatsby"
import { Grid, Button, IconButton, Box, List, ListItem } from "@mui/material"
import Drawer from "@mui/material/Drawer"
import MenuIcon from "@mui/icons-material/Menu"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import { Container } from "../Layout"

import { useSiteMetadata } from "../../hooks/use-site-metadata"

import Logo from "../../images/logo.png"

export default function TemporaryDrawer() {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("md"))
  const { navigation } = useSiteMetadata()
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)

  const handleMobileClick = route => {
    navigate(`${route}`)
    setDrawerIsOpen(false)
  }
  return (
    <>
      <Box bgcolor="#2A1B36">
        <Container>
          <Grid container>
            {isSmall && (
              <Box position="absolute" top="0" left="0">
                <IconButton
                  aria-label="open drawer"
                  onClick={() => setDrawerIsOpen(true)}
                  size="large"
                >
                  <MenuIcon style={{ color: `#fff` }} />
                </IconButton>
              </Box>
            )}

            <Grid item xs={12} md={4}>
              <Box
                display={{ xs: `flex`, md: `block` }}
                justifyContent="center"
              >
                <Box
                  component="img"
                  src={Logo}
                  mb={0}
                  p={`12px`}
                  sx={{ width: "195px" }}
                />
              </Box>
            </Grid>
            {!isSmall && (
              <Grid item md={8}>
                <Box display="flex" justifyContent="flex-end">
                  {navigation.map(el => (
                    <Button
                      onClick={() => handleMobileClick(el.route)}
                      key={el.id}
                      variant="contained"
                    >
                      {el.title}
                    </Button>
                  ))}
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
      <Drawer open={drawerIsOpen} onClose={() => setDrawerIsOpen(false)}>
        <List component="nav" aria-label="mobile navigation">
          {navigation.map(el => (
            <ListItem
              button
              onClick={() => handleMobileClick(el.route)}
              key={el.id}
            >
              {el.title}
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  )
}
