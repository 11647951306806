import React from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"

const Section = ({ children }) => {
  return <Box py={`4rem`}>{children}</Box>
}

Section.propTypes = {
  children: PropTypes.node,
}

export default Section
