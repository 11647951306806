import React from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"

const Container = ({ children }) => {
  return (
    <Box
      m={`0 auto`}
      px={{ xs: `1rem`, md: `initial` }}
      sx={{ maxWidth: `960px` }}
    >
      {children}
    </Box>
  )
}

Container.propTypes = {
  children: PropTypes.node,
}

export default Container
