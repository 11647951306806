import React from "react"
import { navigate } from "gatsby"
import { Box, Button, Typography } from "@mui/material"

import { StyledFooter } from "./Footer.styled"

import IconMoon from "../../images/icon-moon.svg"
import IconTrees from "../../images/icon-trees.svg"

const Footer = () => {
  const currentYear = new Date().getFullYear() // returns the current year
  return (
    <>
      <StyledFooter>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{ minHeight: `330px` }}
        >
          <Box>
            <Box>
              <Box
                display="block"
                component="img"
                src={IconMoon}
                alt="moon icon"
                pt={`2rem`}
                sx={{ margin: `0 auto` }}
              />
            </Box>
          </Box>
          <Box
            display="block"
            component="img"
            src={IconTrees}
            alt="treeline"
            m={0}
            style={{ width: `100%` }}
          />
        </Box>
        <Box></Box>
      </StyledFooter>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ backgroundColor: `#2A1B36` }}
        p={3}
      >
        <Typography paragraph color="textPrimary" align="center">
          {`Copyright Book of the Smith ${currentYear}. All rights reserved.`}
        </Typography>
        <Button color="secondary" onClick={() => navigate("/privacy-policy")}>
          Privacy Policy
        </Button>
      </Box>
    </>
  )
}

export default Footer
